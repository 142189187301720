import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, ListGroup, Modal, Row, Table } from 'react-bootstrap';
import './App.css';
import MyDropzone from './MyDropzone';
import { IMonthlyInvoice, IRowConfig } from './types';

function App() {
  const [items, setItems] = useState<Array<IMonthlyInvoice>>([]);
  const [month, setMonth] = useState((new Date()).getMonth() + 1);
  const [columnDayOffName, setColumnDayOffName] = useState<string>("D");
  const [columnRefundName, setColumnRefundName] = useState<string>("E");
  const [columnTotalName, setColumnTotalName] = useState<string>("F");
  const [rowConfigs, setRowConfigs] = useState<Array<IRowConfig>>([]);
  const [sampleItems, setSampleItems] = useState<Array<any>>([]);
  const [showConfig, setShowConfig] = useState(false);
  const [enableBtnAddConfig, setEnableBtnAddConfig] = useState(false);
  const reasonRef = useRef(null);
  const columnRef = useRef(null);

  useEffect(() => {
    var newSampleItems = [];
    for (var r = 0; r < 3; r++) {
      var sampleRow = [];
      for (var i = 0; i < 2; i++) {
        let invoice: IMonthlyInvoice = {
          id: i,
          name: "Đặng Minh Khôi",
          fee: 2200000,
          number_of_off_day: 6,
          refund: 240000,
          total: 1960000,
          extras: {},
        };
        sampleRow.push(invoice);
      }
      newSampleItems.push(sampleRow);
    }

    setSampleItems(newSampleItems);
  }, [rowConfigs]);

  const formatNumber = (number: number) => {
    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
  }

  let invoiceRows:any = {};
  let rowIndex = 0;
  items.forEach((item, index) => {
    if (index > 0 && index % 2 === 0) {
      ++rowIndex;
    }
    if (invoiceRows[rowIndex] === undefined) {
      invoiceRows[rowIndex] = [];
    }
    invoiceRows[rowIndex].push(item);
  });
  if (invoiceRows[rowIndex] !== undefined && invoiceRows[rowIndex].length === 1) {
    invoiceRows[rowIndex].push({
      id: -1
    });
  }

  const handleChangeMonth = (evt: any) => {
    setMonth(evt.currentTarget.value);
  }

  const handleShowConfig = () => {
    setShowConfig(true);
  }

  const handleConfigValidation = () => {
    if (reasonRef !== null && reasonRef.current !== null && columnRef !== null && columnRef.current !== null) {
      var reasonInput: HTMLInputElement = reasonRef.current;
      var columnInput: HTMLInputElement = columnRef.current;

      if (reasonInput.value !== "" && columnInput.value !== "") {
        setEnableBtnAddConfig(true);
      } else {
        setEnableBtnAddConfig(false);
      }
    }
  }

  const handleAddRowConfig = () => {
    if (reasonRef !== null && reasonRef.current !== null && columnRef !== null && columnRef.current !== null) {
      var reasonInput: HTMLInputElement = reasonRef.current;
      var columnInput: HTMLInputElement = columnRef.current;
      if (reasonInput.value !== "" && columnInput.value !== "") {
        var rowConfig: IRowConfig = {
          text: reasonInput.value,
          column: columnInput.value,
        };
        reasonInput.value = "";
        columnInput.value = "";
        var newRowConfigs: Array<IRowConfig> = [...rowConfigs];
        newRowConfigs.push(rowConfig);
        setRowConfigs(newRowConfigs);
      }
    }
  }

  const handleRemoveRowConfig = (index: number) => {
    var newRowConfigs: Array<IRowConfig> = rowConfigs.filter((row, rowIndex) => rowIndex !== index);
    setRowConfigs(newRowConfigs);
  }

  const render = (invoiceRows: any) => {
    return <>
      {
        Object.keys(invoiceRows).map((rowKey: any) => {
          const rows: Array<IMonthlyInvoice> = invoiceRows[rowKey];
          console.log(rows);
          return <Row className="ttn-row" key={rowKey}>
            {
              rows.map((item, key) => {
                if (item.id === -1) {
                  return <Col key={rowKey + '_' + key}></Col>;
                }
                console.log(item);
                return (<Col key={rowKey + '_' + key}>
                  <h4 className={rowConfigs.length > 0 ? "mb-0" : "mb-1"}>Thông báo học phí tháng {month}</h4>
                  {/* <h5 className="mb-0">Thông báo học phí tháng {month}</h5> */}
                  <Row>
                    <Col className="pt-0 pb-0">
                      <p className={rowConfigs.length > 0 ? "mb-0" : "mb-2"}>TÊN HỌC SINH: {item.name}</p>
                    </Col>
                  </Row>
                  
                  <Table key={`${key}${item.id}`} size="sm" bordered className={rowConfigs.length > 0 ? "mb-1" : "mb-3"}>
                    <thead className="text-center">
                      <tr>
                        <th>Nội dung</th>
                        <th className="num_of_days">Số ngày</th>
                        <th>Tiền</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tiền học</td>
                        <td></td>
                        <td className="text-right">{formatNumber(item.fee)}</td>
                      </tr>
                      {rowConfigs.length > 0 ? (
                        <>
                          {rowConfigs.map(rowConfig => {
                            return (
                              <tr>
                                <td>{rowConfig.text}</td>
                                <td></td>
                                <td className="text-right">{formatNumber(item.extras[rowConfig.column])}</td>
                              </tr>
                            )
                          })}
                        </>
                      ) : null}
                      <tr>
                        <td>Số ngày nghỉ trong tháng {month === 1 ? 12 : month - 1}</td>
                        <td className="text-center">{item.number_of_off_day}</td>
                        <td className="text-right">{formatNumber(item.refund)}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}><strong>Thành tiền</strong></td>
                        <td className="text-right"><strong>{formatNumber(item.total)}</strong></td>
                      </tr>
                    </tbody>
                  </Table>
                  <p className={rowConfigs.length > 0 ? "mb-0" : "mb-1"}>PH có thể đóng qua tài khoản:</p>
                  <p className={rowConfigs.length > 0 ? "mb-0" : "mb-1"}>NH ACB: 150719638 - Chủ TK: Trần Thị Kim Liên</p>
                  <p className={rowConfigs.length > 0 ? "mb-0" : "mb-1"}>Nội dung: {item.name} - Học phí T{month}</p>
                  {/* <p className="mb-0">PH có thể đóng qua tài khoản:</p>
                  <p className="mb-0">NH ACB: 150719638 - Chủ TK: Trần Thị Kim Liên</p>
                  <p className="mb-0">Nội dung: {item.name} - Học phí T{month}</p> */}
                </Col>)
              })
            }
          </Row>
        })
      }
    </>;
  }

  return (
    <Container fluid>
      {items.length === 0 ? <>
        <form>
          <Form.Group as={Row} className="mb-3">
            <Col sm="2">Tháng</Col>
            <Col sm="4"><input className="form-control" id="month" aria-describedby="emailHelp" placeholder="Nhập tháng" onKeyUp={handleChangeMonth} defaultValue={month} /></Col>
            <Col sm="2">
              <Button variant="primary" onClick={handleShowConfig}>Cài đặt</Button>
            </Col>
          </Form.Group>
          <div className="form-group">
            <MyDropzone 
              onChange={setItems}
              columnDayOffName={columnDayOffName}
              columnRefundName={columnRefundName}
              columnTotalName={columnTotalName}
              rowConfigs={rowConfigs}
            />
          </div>
        </form>
        <div>
          {render(sampleItems)}
        </div>
      </> : render(invoiceRows)}

      <Modal show={showConfig} onHide={() => setShowConfig(false)} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Cài đặt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="4">
              <Form.Group className="mb-3">
                <Form.Label>Cột số ngày nghỉ</Form.Label>
                <Form.Control type="text" defaultValue={columnDayOffName} onChange={(evt: any) => setColumnDayOffName(evt.currentTarget.value.toUpperCase())} />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <Form.Label>Cột số tiền trừ</Form.Label>
                <Form.Control type="text" defaultValue={columnRefundName} onChange={(evt: any) => setColumnRefundName(evt.currentTarget.value.toUpperCase())} />
              </Form.Group>
            </Col>
            <Col sm="4">
              <Form.Group className="mb-3">
                <Form.Label>Cột tổng</Form.Label>
                <Form.Control type="text" defaultValue={columnTotalName} onChange={(evt: any) => setColumnTotalName(evt.currentTarget.value.toUpperCase())} />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group as={Row} className="mb-3">
            <Col sm="4">
              <Form.Control type="text" ref={reasonRef} placeholder="Ví dụ: Học Aerobic, Học phẩm cả năm..." onChange={handleConfigValidation} />
            </Col>
            <Col sm="4">
              <Form.Control type="text" ref={columnRef} placeholder="Nhập tên cột..." onChange={handleConfigValidation} />
            </Col>
            <Col sm="4"><Button variant="primary" onClick={handleAddRowConfig} disabled={!enableBtnAddConfig}>Thêm</Button></Col>
          </Form.Group>
          <ListGroup variant="flush">
          {
            rowConfigs.map((rowConfig, index) => 
              <ListGroup.Item>
                <Row key={index}>
                  <Col xs={6}>{rowConfig.text}</Col>
                  <Col xs={4}>{rowConfig.column}</Col>
                  <Col className="text-right"><Button variant="danger" onClick={() => handleRemoveRowConfig(index)}>Xóa</Button></Col>
                </Row>
              </ListGroup.Item>
            )
          }
          </ListGroup>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default App;
