import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import { IMonthlyInvoice } from './types';

const ExcelColumnToIndex: any = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  E: 4,
  F: 5,
  G: 6,
  H: 7,
  I: 8,
  J: 9,
  K: 10,
  L: 11,
  M: 12,
  N: 13,
};

function MyDropzone(props: any) {  
  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach(async (file: any) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.readAsArrayBuffer(file)
      let rows: Array<any> = await readXlsxFile(file);
      let newItems: Array<IMonthlyInvoice> = [];
      const { rowConfigs } = props;
      rows.forEach((row: any) => {
        if (typeof row[0] == 'number') {
          if (row[1] !== '') {
            let invoice: IMonthlyInvoice = {
              id: row[0],
              name: row[1],
              fee: row[2],
              number_of_off_day: row[ExcelColumnToIndex[props.columnDayOffName]],
              refund: row[ExcelColumnToIndex[props.columnRefundName]] * -1,
              total: row[ExcelColumnToIndex[props.columnTotalName]],
              extras: {},
            };
            if (rowConfigs.length > 0) {
              rowConfigs.forEach((config: any) => {
                var columName = config.column;
                invoice.extras[columName] = row[ExcelColumnToIndex[columName]];
              })
            }
            newItems.push(invoice);
          }
        }
      });
      props.onChange(newItems);
    }) 
  }, [props])
  const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({accept: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.ms-excel': ['.xls'],
  }, onDrop});
  const additionalClass = isDragAccept
        ? "accept"
        : isDragReject
        ? "reject"
        : "";

  return (
    <div {...getRootProps({
        className: `dropzone ${additionalClass}`
    })}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}

export default MyDropzone;